"use client";

import {useState} from "react";
import Image from "next/image";
import {useTranslations} from "next-intl";
import {usePathname, useRouter} from "@/navigation";
import {Locale} from "@/i18n";

type HeaderLocaleSwitcherProps = {
	theme: string;
	locale: Locale;
};

export default function HeaderLocaleSwitcher({theme, locale}: HeaderLocaleSwitcherProps) {
	const t = useTranslations("header");

	const [showFlagMenu, setShowFlagMenu] = useState(false);
	const toggleFlag = () => {
		setShowFlagMenu(!showFlagMenu);
	};

	const pathName = usePathname();
	const router = useRouter();

	const dropDownMenuBGStyle = theme === 'light'? "" : 'bg-light';

	let languages = [
		{
			"locale": "en",
			"img": '/assets/language-flags/uk.svg',
			"label": t("languages.en"),
			"selected": false,
		},
		{
			"locale": "bg",
			"img": '/assets/language-flags/bg.svg',
			"label": t("languages.bg"),
			"selected": false,
		},
	];
	languages = languages.map(language => ({
		...language,
		selected: language.locale === (locale as string),
	}));


	// loop over and add `active`


	return (
		<>
			{languages.map((item) => (
				<li className='nav-item' key={item.locale}>
					<a
						href="#"
						onClick={() => {
							router.replace(pathName, {locale: item.locale});
						}}
						className={'dropdown-item me-4' + (item.selected ? ' fw-bold text-dark active' : '')}
					>
						<Image
							className="fa-fw me-2"
							src={item.img}
							height="13"
							width="30"
							alt="language flag"
						/>
						{item.label}
					</a>
				</li>
			))}
		</>
	);
}
