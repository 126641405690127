"use client";
import {Locale} from "@/i18n";
import React, {useEffect, useState} from "react";
import Image from "next/image";
import {Link} from "@/navigation";
import {useTranslations} from "next-intl";
import HeaderLocaleSwitcher from "./header-locale-switcher";
import {usePathname} from "next/navigation";
import Headroom from "headroom.js";

const options = {
	tolerance: {
		up: 5,
		down: 10
	},
}

type HeaderProps = {
	params: { locale: Locale };
};

// Pages with white backgrounds
const lightPages: string[] = [
	"^$",
	"^blog$",
	"^legal*",
];

function Header({params: {locale}}: HeaderProps) {
	const t = useTranslations("header");
	const pathname = usePathname();


	// skip the language, returns `blog` or `pricing`
	const extractedPath = pathname.split("/").slice(2).join("/");

	const navbarTheme = lightPages.some((path) => extractedPath.match(path))
		? "light"
		: "dark";

	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const toggleMenu = () => {
		setShowMobileMenu(!showMobileMenu);
	};

	useEffect(() => {
		const element = document.querySelector("body");
		if(element) {
			const headroom = new Headroom(element, options);
			headroom.init();
		}
	}, [])


	return (
		<nav
			id="mainNav"
			className={`navbar navbar-expand-lg navbar-sticky navbar-${navbarTheme}`}
			data-nosnippet
		>
			<div className="container">

				{/* Logo */}
				<Link href="/" className="navbar-brand" locale={locale}>
					<Image
						src={
							navbarTheme === "dark"
								? '/assets/brands/medentic-logo-light.svg'
								: '/assets/brands/medentic-logo-dark.svg'
						}
						alt="Medentic logo"
						width={35}
						height={35}
					/>
				</Link>

				{/* Right side */}
				<ul className={`navbar-nav navbar-nav-secondary order-lg-3`}>
					{/* Mobile Dropdown icon */}
					<li className="nav-item d-lg-none">
						<a className="nav-link nav-icon" onClick={toggleMenu}>
							<span className="bi bi-globe"></span>
						</a>
					</li>

					{/* Desktop CTA */}
					<li className="dropdown nav-item d-none d-lg-block">
						<a
							className="nav-link small"
							href="#"
							role="button"
							id="languageDropdown"
							data-bs-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<i className="bi bi-globe fa-fw me-1"/> {t("languages." + locale)}
						</a>
						<ul className={`dropdown-menu dropdown-animation ${navbarTheme} dropdown-menu-end min-w-auto`}
							aria-labelledby="languageDropdown"
						>
							<HeaderLocaleSwitcher theme={navbarTheme} locale={locale} />
						</ul>
					</li>


				</ul>

				{/* Primary */}
				<div
					className={`collapse navbar-collapse ${showMobileMenu && "show"}`}
					id="navbar"
					data-bs-parent="#mainNav"
				>
					<ul className="navbar-nav d-lg-none">
						<HeaderLocaleSwitcher theme={navbarTheme} locale={locale} />
					</ul>
				</div>

				{/* Mobile User Menu */}
			</div>
		</nav>
	);
}

export default Header;
